<template>
<!--  <div @click="focusInput">-->
  <div>
    <div class="row">
      <div class="col-12">
        <card>
          <div class="last-update">
            <span v-if="lastUpdate">Ultimo aggiornamento: {{ lastUpdate }}</span>
          </div>
<!--          <div class="input-div">
            <span v-if="workingRotateError" class="workingRotateError">Lavorazione {{ last_registration_number }} non trovata </span>
            <span v-if="workingRotateOK" class="workingRotateOK">Lavorazione {{ last_registration_number }} aggiornata </span>
            <input type="text"
                   name="registration_number"
                   id="registration_number"
                   ref="registrationInput"
                   v-model="registration_number"
                   @keyup.enter="updateDocumentStatus"
            />
            <button ref="okButton" @click="updateDocumentStatus">OK</button>
          </div>-->
          <table class="workings">
            <thead>
              <tr>
                <th>Stato</th>
                <th>Cliente</th>
                <th>Buono di carico</th>
                <th>Salta la fila</th>
                <th>Data/ora prenot.</th>
                <th>Peso netto</th>
                <th>Tipo doc</th>
                <th>Operatore</th>
              </tr>
            </thead>
            <tbody>

            <template v-for="(row, index) in workings" v-if="row.document_type.id == 2">
              <tr>
                <td colspan="8" class="spacer-tr"></td>
              </tr>
              <tr :key="row.registration_number"
                  :class="{'blink': row.registration_number === blinkingRow}"
              >
                <!--  <td :class="'document-status-' + row.document_status.id">{{ row.document_status.name }}</td> -->
                <td>{{ row.order_status }}</td>
                <td>{{ row.company_name.substring(0, 30) }}</td>
                <td>{{ row.document_number }}</td>
                <td :class="'priority-' + row.priority">{{ (row.priority == 1) ? 'SI' : '' }}</td>
                <td>{{ row.reservation_date_fmt }}</td>
                <td><span v-if="row.weight">{{ row.weight }}</span></td>
                <td>{{ row.document_type.name }}</td>
                <td>{{ row.operator }}</td>

              </tr>
            </template>

            <tr>
              <td colspan="8" class="spacer-tr"></td>
            </tr>
            <tr>
              <td colspan="8" style="height: 40px;"></td>
            </tr>
            <tr>
              <th>Stato</th>
              <th>Cliente</th>
              <th>Buono di carico</th>
              <th>Salta la fila</th>
              <th>Data/ora prenotazione</th>
              <th>Peso netto</th>
              <th>Tipo doc</th>
              <th>Operatore</th>
            </tr>

            <template v-for="(row, index) in workings" v-if="row.document_type.id == 3">
              <tr>
                <td colspan="8" class="spacer-tr"></td>
              </tr>
              <tr :key="row.registration_number"
                  :class="{'blink': row.registration_number === blinkingRow}"
              >
                <!--  <td :class="'document-status-' + row.document_status.id">{{ row.document_status.name }}</td> -->
                <td>{{ row.order_status }}</td>
                <td>{{ row.company_name.substring(0, 30) }}</td>
                <td>{{ row.document_number }}</td>
                <td :class="'priority-' + row.priority">{{ (row.priority == 1) ? 'SI' : '' }}</td>
                <td>{{ row.reservation_date_fmt }}</td>
                <td><span v-if="row.weight">{{ row.weight }}</span></td>
                <td>{{ row.document_type.name }}</td>
                <td>{{ row.operator }}</td>
              </tr>
            </template>

            <tr>
              <td colspan="8" class="spacer-tr"></td>
            </tr>
            </tbody>
          </table>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { ListTable } from '@/theme/components/index'

import secureStorage from '@/utils/secureStorage'

export default {
  components: {
    ListTable
  },

  data () {
    return {
      registration_number: null,
      last_registration_number: null,
      blinkingRow: null,
      workingRotateError: false,
      workingRotateOK: false,
      lastUpdate: null,
      query: {
        rowsPerPage: 10,
        page: 1,
        sort: null,
        filter: {
          custom_search: ''
        }
      },
      pagination: {
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      searchFields: [
        {
          type: 'input',
          propName: 'custom_search',
          name: 'Cerca'
        }
      ],

    }
  },

  computed: {
    ...mapGetters({
      workings: 'workings/items',
      meta: 'workings/meta',
    })
  },

  watch: {
    query: {
      handler () {
        this.handleFetch()
      },
      deep: true
    },
    meta: {
      handler (val) {
        this.pagination.total = val.total
      },
      deep: true
    }
  },

  created() {
    const storedQuery = JSON.parse(secureStorage.getItem('tableQuery'))
    if (storedQuery && storedQuery.entity === 'workings') {
      this.query = storedQuery.query
    } else {
      this.handleFetch()
    }
  },
  mounted() {
    // this.focusInput(); // Focalizza l'input al caricamento della pagina

    // Imposta l'intervallo per eseguire handleFetch ogni 5 minuti (300000 ms)
    this.fetchInterval = setInterval(() => {
      this.handleFetch();
    }, 300000); // 300000 ms = 5 minuti

    // Event listener per i clic fuori dall'input e dal pulsante OK
    // document.addEventListener('click', this.handleOutsideClick);
  },
  beforeDestroy() {
    this.clearWorkings()

    // Rimuove l'intervallo quando il componente viene distrutto
    clearInterval(this.fetchInterval);

    // Rimuove l'event listener quando il componente viene distrutto
    // document.removeEventListener('click', this.handleOutsideClick);
  },

  methods: {
    ...mapActions({
      fetchWorkings: 'workings/fetch',
      rotateDocumentStatus: 'workings/rotateDocumentStatus',
      clearWorkings: 'workings/resetItems'
    }),
    handleFetch() {
      this.fetchWorkings(this.query)
        .then(() => {
          // Imposta il lastUpdate con la data e ora corrente
          const now = new Date()
          this.lastUpdate = now.toLocaleString() // Formatta la data e ora come stringa leggibile
          secureStorage.setItem('tableQuery', JSON.stringify({ entity: 'workings', query: this.query }))
        })
    },
    /*updateDocumentStatus() {
      this.workingRotateError = false
      this.workingRotateOK = false
      this.last_registration_number = this.registration_number

      this.rotateDocumentStatus({ registration_number: this.registration_number }).then((res) => {
        this.fetchWorkings(this.query).then((res) => {
          this.blinkRow(this.registration_number)
          this.registration_number = null
        }, (error) => {
          this.registration_number = null
        })
        this.registration_number = null
        this.workingRotateOK = true
      }, (error) => {
        this.registration_number = null
        this.workingRotateError = true
      })
    },
    blinkRow(registration_number) {
      this.blinkingRow = registration_number

      // Rimuove la classe di lampeggio dopo 2 secondi
      setTimeout(() => {
        this.blinkingRow = null
      }, 2000)
    },
    focusInput() {
      // Metodo per focalizzare l'input
      this.$refs.registrationInput.focus()
    },
    handleOutsideClick(event) {
      const inputElement = this.$refs.registrationInput
      const okButtonElement = this.$refs.okButton

      // Verifica se il clic è avvenuto al di fuori dell'input e del pulsante OK
      if (
        inputElement &&
        !inputElement.contains(event.target) &&
        okButtonElement &&
        !okButtonElement.contains(event.target)
      ) {
        this.focusInput()
      }
    }*/
  }
}
</script>

<style>
TABLE.workings {
  width: 100%;
  border-spacing: 20px 10px;
  border-collapse: unset;
}

TABLE.workings TH {
  font-weight: bold;
  border: 1px solid #0694D3;
  border-radius: 8px;
  color: #0694D3;
  text-align: center;
}

TABLE.workings TD {
  text-align: center;
}

TABLE.workings TR {
  border-bottom: 1px solid black;
}
.document-status-1 {
  font-weight: bold;
  background-color: white;
  border: 1px solid #022f44;
  color: #022f44;
  border-radius: 8px;
}
.document-status-2 {
  font-weight: bold;
  background-color: yellow;
  color: black;
  border-radius: 8px;
  border: 1px solid #c4980f;
}
.document-status-3 {
  font-weight: bold;
  background-color: darkgreen;
  color: white;
  border-radius: 8px;
  border: 1px solid #65675d;
}
.spacer-tr {
  background-color: #9fa4b2;
  height: 1px;
}
.spacer-tr TD {
  background-color: #9fa4b2;
  height: 1px;
}
.priority-1 {
  font-weight: bold;
  color: #0694D3;
}
.priority-0 {
  color: white;
}
.blink {
  animation: blink-animation 0.3s steps(2, start) 3;
}
@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
.input-div {
  text-align: right;
  margin-right: 20px;
}
.input-div INPUT {
  border: 1px solid #0694D3;
  border-right: 0;
  border-radius: 8px 0 0 8px;
  color: #0694D3;
  padding-left: 8px;
  outline: none;
  box-shadow: none;
}
.input-div INPUT:focus {
  border-color: #0694D3;
  outline: none;
  box-shadow: none;
}
.input-div BUTTON {
  border: 1px solid #0694D3;
  border-radius: 0 8px 8px 0;
  color: #0694D3;
}
.workingRotateOK {
  font-weight: bold;
  color: darkgreen;
}
.workingRotateError {
  font-weight: bold;
  color: darkred;
}
.last-update {
  float: left;
  font-weight: bold;
  color: #0694D3;
  text-align: left;
  margin-left: 20px;
}
</style>
